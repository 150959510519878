import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import Link from "gatsby-link";
import GET_CUSTOMER_ORDER from "../../../queries/get-customer-orders";
import { getFormattedDate } from "../../../utils/functions";

const Orders = ({ authData }) => {
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    refetch();
  }, [loading]);

  const {
    user: { id },
  } = authData;

  // Get Cart Data.
  const { data, refetch } = useQuery(GET_CUSTOMER_ORDER, {
    variables: {
      id: id,
    },
    onCompleted: () => {
      if (data?.customer?.orders?.edges) {
        setOrders(data.customer.orders.edges);
        setLoading(false);
      }
    },
    onError: (error) => {
      if (error) {
        console.log('Error010:', error.graphQLErrors);
        //setRequestError(error.graphQLErrors[0].message);
      }
    },
  });

  //console.log('orders:',orders);

  if (!orders) {
    return null;
  }

  if (loading) {
    //return null;
  }

  return (
    <div className="">
      {!isEmpty(orders) ? (
        orders.map((order) => {
          return (
            <div className="" key={order.node.orderKey}>
              <div className="card-header">
                <h3>Order #{order.node.orderKey}</h3>
                <time>Order Placed: {getFormattedDate(order.node.date)}</time>
                <div>Payment Method: {order.node.paymentMethodTitle}</div>
                <div>Order Status: {order.node.status}</div>
                <div>Total: {order.node.total}</div>
              </div>
              <div className="card-body">
                {order.node.lineItems.edges.map((item, index) => {
                  return (
                    <div className="order-item" key={index}>
                      <h5>{item.node.product.name}</h5>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })
      ) : (
        <div className="p-3">
          <h4 className="mb-3">No orders found</h4>
          <Link to="/">
            <button className="btn-outline-dark" style={{ fontSize: "12px", padding: "8px 12px" }}>
              Shop now
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Orders;
