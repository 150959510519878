import React, { useState, useEffect, useContext } from "react";
import { isEmpty } from "lodash";
import { Layout } from "../../../../src/components";
import Login from "../../components/login";
import Register from "../../components/register";
import { isUserLoggedIn, logOut } from "../../utils/functions";
import "./style.scss";
import CustomerAccount from "../../components/customer-account";
import { AppContext } from "../../components/context/AppContext";

const MyAccount = () => {
  //const [loggedIn, setLoggedIn] = useState(false);
  //const [auth, setAuth] = useState(false);
  const { auth, setAuth } = useContext(AppContext);

  // useEffect(() => {
  //   //const chackAuth = isUserLoggedIn();
  //   if (!isEmpty(auth)) {
  //     setLoggedIn(true);
  //     //setAuth(chackAuth);
  //   }
  // }, [loggedIn]);

  // const handleLogout = () => {
  //   //logOut();
  //   setLoggedIn(false);
  //   setAuth(null);
  // };

  return (
    <Layout>
      <div className="layout">
        {!auth ? (
          <div className="account-details">
            <div className="row">
              <Login />
              <Register />
            </div>
          </div>
        ) : (
          <div className="account-details">
            <CustomerAccount />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default MyAccount;
