import React, { useState, useEffect, useContext } from "react";
import "./style.scss";
import { AppContext } from "../../components/context/AppContext";
import Dashboard from "./dashboard";
import Orders from "./orders";
import Logout from "./logout";
import Addresses from "./addresses";
import AccountDetails from "./account-details";
import "./style.scss";
//const auth = isUserLoggedIn();

const CustomerAccount = () => {
  const { auth } = useContext(AppContext);
  const [active, setActive] = useState(1);

  const tabItems = [
    {
      id: 1,
      title: "Dashboard",
      icon: "tabitem__icon tab-dashboard",
      content: <Dashboard authData={auth} />,
    },
    {
      id: 2,
      title: "Orders",
      icon: "tabitem__icon tab-users",
      content: <Orders authData={auth} />,
    },
    {
      id: 3,
      title: "Addresses",
      icon: "tabitem__icon tab-addresses",
      content: <Addresses authData={auth} />,
    },
    {
      id: 4,
      title: "Account Details",
      icon: "tabitem__icon tab-account-details",
      content: <AccountDetails authData={auth} />,
    },
    {
      id: 5,
      title: "Logout",
      icon: "tabitem__icon tab-logout",
      content: "",
    },
  ];

  if (!auth) {
    return null;
  }

  const TabItemComponent = ({
    icon = "",
    title = "",
    onItemClicked = () => console.error("You passed no action to the component"),
    isActive = false,
  }) => {
    return (
      <div className={isActive ? "active" : ""}>
        <button onClick={onItemClicked} className="action">{title}</button>
      </div>
    );
  };

  return (
    <div className="row">
      <div className="account-details-menu">
        {tabItems.map(({ id, icon, title }) =>
          5 === id ? (
            <Logout key={title} />
          ) : (
            <TabItemComponent
              key={title}
              icon={icon}
              title={title}
              onItemClicked={() => setActive(id)}
              isActive={active === id}
            />
          ),
        )}
      </div>
      <div className="account-details-content">
        {tabItems.map(({ id, content }) => {
          return active === id ? <div key={id}>{content}</div> : "";
        })}
      </div>
    </div>
  );
};

export default CustomerAccount;
