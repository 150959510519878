import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import GET_CUSTOMER_ADDRESS from "../../../queries/get-customer-address";

const Addresses = ({ authData }) => {
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    refetch();
  }, [loading]);

  const {
    user: { id },
  } = authData;

  // Get Cart Data.
  const { data, refetch } = useQuery(GET_CUSTOMER_ADDRESS, {
    variables: {
      id: id,
    },
    onCompleted: () => {
      if (data?.customer) {
        setAddress(data.customer);
        setLoading(false);
      }
    },
    onError: (error) => {
      if (error) {
        console.log(error.graphQLErrors);
        //setRequestError(error.graphQLErrors[0].message);
      }
    },
  });

  if (!address) {
    return null;
  }

  const { billing, shipping } = address;

  return (
    <div className="card-body addresses">
      {!isEmpty(billing) ? (
        <div className="billing-address">
          <h3>Billing Address</h3>
          {!isEmpty(billing.address1) ? <p>{billing.address1}</p> : null}
          {!isEmpty(billing.address2) ? <p>{billing.address2}</p> : null}
          {!isEmpty(billing.city) ? <p>{billing.city}</p> : null}
          {!isEmpty(billing.state) ? <p>{billing.state}</p> : null}
          {!isEmpty(billing.country) ? <p>{billing.country}</p> : null}
        </div>
      ) : null}
      {!isEmpty(shipping) ? (
        <div className="shipping-address">
          <h3>Shipping Address</h3>
          {!isEmpty(shipping.address1) ? <p>{shipping.address1}</p> : null}
          {!isEmpty(shipping.address2) ? <p>{shipping.address2}</p> : null}
          {!isEmpty(shipping.city) ? <p>{shipping.city}</p> : null}
          {!isEmpty(shipping.state) ? <p>{shipping.state}</p> : null}
          {!isEmpty(shipping.country) ? <p>{shipping.country}</p> : null}
        </div>
      ) : null}
    </div>
  );
};

export default Addresses;
