import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation LOGIN($input: LoginInput!) {
    login(input: $input) {
      authToken
      user {
        id
        username
        name
        email
        firstName
        lastName
        avatar {
          url
        }
      }
      customer {
        billing {
          address1
          address2
          city
          company
          country
          email
          firstName
          lastName
          phone
          postcode
          state
        }
      }
    }
  }
`;

export default LOGIN;
