import React from "react";
import { isEmpty } from "lodash";

const Dashboard = ({ authData }) => {
  if (isEmpty(authData)) {
    return null;
  }

  const { user } = authData;

  return (
    <div className="card-body">
      <div className="">
        {!isEmpty(user.firstName) || !isEmpty(user.lastName) ? (
          <h2>Hi {user.firstName}</h2>
        ) : (
          <p>Hello {user.username}</p>
        )}
        <section>
          From your account dashboard you can view your recent orders, manage your shipping and billing addresses
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
