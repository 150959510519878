import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const Logout = () => {
  const { setAuth } = useContext(AppContext);

  const logout = () => {
    console.log('logout')
    localStorage.removeItem("auth");
    setAuth(null);
  };

  return (
    <button onClick={logout} className="action">
      Log out
    </button>
  );
};

export default Logout;
